import {Link} from "react-router-dom";
import {ATD, FlagiHere, FlagiUl, FlagiUlReturn, Hizha, Roblox, RosaWebZin, Vsealizm, Vsealizm2} from "../urls/Urls";
import TextRing from "./TextRing";
import sphere from "../static/4_glass sphere.png";
import lemons from "../static/16_lemons.png";
import screw from "../static/9_screw.png";
import dragon from "../static/20_dragon fruit.png";
import snake from "../static/10_snake.png";
import wave from "../static/3_wave.png"
import box from "../static/15_box.png"
import eyeball from "../static/14_eyeball.png"
import {isMobileDeviceV2} from "../../utils/StyleUtils";
import sunflower from "../static/19_sunflower.png";

const Pub = () => {
    let isMob = isMobileDeviceV2()
    return (
        <div className={"projects"}>
            <div className={"grid-container"}>
                <div className={"grid-item"}>
                    <Link to={ATD}>
                        <TextRing side={isMob ? 2.1 : 1.9} bottom={isMob ? -3.5 : -4.5} left={isMob ? 2.5 : 5}
                                  turn1={0.2} turn2={0.1}>АТД</TextRing>
                        <img className={"link-img position-0"} src={dragon} alt={"moon"}/>
                    </Link>
                </div>
                <div className={"grid-item"}>
                    <Link to={FlagiHere}>
                        <TextRing side={isMob ? 0.65 : 0.8} bottom={isMob ? 3 : 13} left={isMob ? 2 : 4} revert={true}
                                  turn1={-0.2} turn2={-0.2}>здесь в журнале флаги</TextRing>
                        <img className={"link-img position-1"} src={lemons} alt={"moon"}/>
                    </Link>
                </div>
                <div className={"grid-item"}>
                    <Link to={FlagiUl}>
                        <TextRing side={1.5} bottom={isMob ? 11 : 7} left={isMob ? 11 : 17} turn1={0.1} turn2={0.2}>ул.
                            в журнале флаги</TextRing>
                        <img className={"link-img position-0"} src={screw} alt={"moon"}/>
                    </Link>
                </div>
                <div className={"grid-item"}>
                    <Link to={FlagiUlReturn}>
                        <TextRing side={isMob ? 0.47 : 0.56} bottom={isMob ? 3 : 12} left={isMob ? 4 : 3.5}
                                  turn1={isMob ? 0.29 : 0.24} turn2={isMob ? 0.15 : 0.11}>ул. | невозвратное в журнале
                            флаги</TextRing>
                        <img className={"link-img position-1"} src={sphere} alt={"moon"}/>
                    </Link>
                </div>
                <div className={"grid-item"}>
                    <Link to={Hizha}>
                        <TextRing side={isMob ? 0.8 : 1.5} bottom={isMob ? 0 : -5} left={isMob ? 3.5 : 9} turn1={-0.2}
                                  turn2={-0.1} revert={true}>невозвратное на хиже</TextRing>
                        <img className={"link-img position-0"} src={snake} alt={"moon"}/>
                    </Link>
                </div>
                <div className={"grid-item"}>
                    <Link to={Vsealizm}>
                        <TextRing side={isMob ? 0.55 : 0.7} bottom={isMob ? 2 : 11} left={isMob ? 5 : 5} turn1={-0.3}
                                  turn2={-0.005} revert={true}>всеализм: маленькие улицы</TextRing>
                        <img className={"link-img position-1"} src={wave} alt={"moon"}/>
                    </Link>
                </div>
                <div className={"grid-item"}>
                    <Link to={Roblox}>
                        <TextRing
                                  side={isMob ? 0.50 : 0.55}
                                  bottom={isMob ? -2 : 13}
                                  left={isMob ? 3 : 3}
                                  turn1={isMob ? -0.28 : 0.28}
                                  turn2={isMob ? -0.11 : 0.05}
                                  revert={isMob}>stock | poetry showcase -> nPoems
                            (roblox)</TextRing>
                        <img className={"link-img position-1"} src={box} alt={"moon"}/>
                    </Link>
                </div>
                <div className={"grid-item"}>
                    <Link to={Vsealizm2}>
                        <TextRing side={isMob ? 0.50 : 0.65} bottom={isMob ? 4 : 10} left={isMob ? 4 : 5}
                                  turn1={isMob ? -0.28 : 0.25}
                                  turn2={isMob ? -0.11 : -0.01} revert={isMob}>всеализм: lpp ooo1-o11o
                        </TextRing>
                        <img className={"link-img position-1"} src={eyeball} alt={"moon"}/>
                    </Link>
                </div>
                <div className={"grid-item"}>

                    <Link to={RosaWebZin}>
                        <TextRing
                            side={isMob ? 0.50 : 0.55}
                            bottom={isMob ? 3 : 4}
                            left={isMob ? 3 : 18}
                            turn1={isMob ? -0.28 : 0.28}
                            turn2={isMob ? -0.11 : 0.30}
                            // side={isMob ? 0.9 : 1}
                            // bottom={isMob ? -5 : 10}
                            // left={isMob ? 0 : 20}
                            // turn1={0.5}
                            // turn2={0.35}
                            revert={isMob}>rosamundi web zin: lpp ooo1-oo11</TextRing>
                        <img className={"link-img position-2"} src={sunflower} alt={"moon"}/>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Pub