export const WebAlVol1 =  "https://www.web-almanac.com/"
export const WebAlBot ="https://t.me/WebAlmanacBot"
export const BitcrushPoem = "https://www.vol-1.web-almanac.com/bitcrushpoem/"
export const ATD = "https://atd-premia.ru/2020/09/10/али-алиев-россия-москва/"
export const FlagiHere = "https://flagi.media/piece/199"
export const FlagiUl = "https://flagi.media/piece/400"
export const FlagiUlReturn = "https://flagi.media/piece/461"
export const Hizha = "http://khizha.tilda.ws/aliv"
export const Vsealizm = "https://vsealism.ru/ali-aliev-m-a-l-e-n-k-i-e-u-l-i-c-y/"
export const Vsealizm2 = "https://vsealism.ru/ali-aliev-low-poly-poems-ooo1-o11o/"
export const RosaWebZin = "https://daich68.github.io/rosamundi-web-zin/"
export const Roblox = "https://www.roblox.com/games/15636076452/stock-poetry-showcase"
export const TgWebAl = "https://t.me/web_almanac"
export const TgUl = "https://t.me/alialievulidrugiestihi"
export const KM = "https://katyamezentseva.com/"
export const AR = "https://areas.work/"
export const TgMy = "https://t.me/thealialiev"
export const TgPod = "https://t.me/podstrochnik_project"
export  const FbMy = "https://www.facebook.com/ali.aliev.988711?mibextid=LQQJ4d"
export  const InMy = "https://www.instagram.com/alialiev.ul?igsh=a3Z5ampodmc3czVo&utm_source=qr"